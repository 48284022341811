import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import Title from './Title';
import { useState, useEffect } from "react";

function preventDefault(event) {
  event.preventDefault();
}

const data = [
  {
    id: 1,
    utm: "https://www.tocdocs.com/",
    count: 8160
  },
  {
    id: 2,
    utm: "https://www-tocdocs-com.translate.goog",
    count: 3
  }
]

const columns = [
  { field: 'timestamp', headerName: 'Date', width: 30 },
  { field: 'name', headerName: 'Event', width: 30 },
  { field: 'url', headerName: 'URL', width: 130 },
];

export default function CustomerEvents() {
//   const [data, setData] = useState([]);
//   useEffect(() => {
//     fetch('https://intrepy-tracking-api-prod.fly.dev/events?domain=carolinaona.com')
//        .then((response) => response.json())
//        .then((events) => {
//           fetch('https://intrepy-tracking-api-prod.fly.dev/forms?domain=carolinaona.com')
//           .then((response) => response.json())
//           .then((forms) => {
//             let temp = []

//             for (const element of events) {
//               if (element.ip = "50.215.227.233") {
//                 temp.push({
//                   timestamp: element.timestamp,
//                   name: element.name,
//                   url: element.url
//                 })
//               }
//             }

//             for (const element of forms) {
//               if (element.ip = "50.215.227.233") {
//                 temp.push({
//                   timestamp: element.timestamp,
//                   name: "Form Fill",
//                   url: element.domain
//                 })
//               }
//             }

//             setData(temp.sort((x, y) => {
//               return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
//             }).reverse());
//           })
//           .catch((err) => {
//             console.log(err.message);
//           });
//        })
//        .catch((err) => {
//           console.log(err.message);
//        });
//  }, []);
  return (
    <React.Fragment>
      <Title>Recent Events</Title>
      {/* <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[10]}
      /> */}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>URL</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.utm}</TableCell>
              <TableCell>{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more events
      </Link> */}
    </React.Fragment>
  );
}