import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './listItems';
import Chart from './Chart';
import Events from './Events';
import Forms from './Forms';
import Sources from './Sources';
import Tracking from './Tracking';
import Conversions from './Conversions';
import { SourceSharp } from '@mui/icons-material';
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function DashboardContent() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [startDate, setStartDate] = useState(queryParams.get('startDate') || dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(queryParams.get('endDate') || dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
  const [domain, setDomain] = useState(queryParams.get('domain') || "intrepy.com")

  const [data, setData] = useState({
    top_base_urls: [{}],
    top_utms: [{}],
    top_referrers: [{}],
    conversions: [{}],
    url_conversions: [{}],
    form_conversions: [{}],
  });
  useEffect(() => {
    fetch(`https://intrepy-tracking-api-prod.fly.dev/dashboard?domain=${domain}&fromDate=${startDate}&toDate=${endDate}`)
       .then((response) => response.json())
       .then((data) => {
          data.top_base_urls = data.top_base_urls.sort((a, b) => b.count - a.count).slice(0, 5);
          data.top_utms = data.top_utms.sort((a, b) => b.count - a.count).slice(0, 5);
          data.conversions = data.conversions.sort((a, b) => b.count - a.count).slice(0, 5);
          data.top_referrers = data.top_referrers.sort((a, b) => b.count - a.count).slice(0, 5);
          data.url_conversions = data.url_conversions.sort((a, b) => b.count - a.count).slice(0, 5);
          data.form_conversions = data.form_conversions.sort((a, b) => b.count - a.count).slice(0, 5);
          setData(data);
       })
       .catch((err) => {
          console.log(err.message);
       });
 }, [startDate, endDate]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              {/* <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid> */}
              {/* Tracking Items */}
              <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Beginning Date"
                    value={dayjs(startDate)}
                    onChange={(date) => setStartDate(date.format("YYYY-MM-DD"))}
                  />
                  <DatePicker
                    label="End Date"
                    value={dayjs(endDate)}
                    onChange={(date) => setEndDate(date.format("YYYY-MM-DD"))}
                  />
                </LocalizationProvider>
                <TextField
                  id="outlined-basic"
                  label="Domain"
                  variant="outlined"
                  value={domain} // Bind the value to the state
                  onChange={(domain) => setDomain(domain.target.value)} // Handle input changes
                />
              </Grid>
              <Grid item xs={6} sx={{ marginBottom: '1rem' }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Events events={data.top_base_urls} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Forms events={data.top_utms} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Sources events={data.top_referrers}/>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Conversions events={data.conversions} title={"Conversions"} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Conversions events={data.url_conversions} title={"URL Conversions"} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Conversions events={data.form_conversions} title={"Form Conversions"} />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}