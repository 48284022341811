import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import Title from './Title';
import { useState, useEffect } from "react";

export default function Sources(props) {
  return (
    <React.Fragment>
      <Title>Referrer</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Referrer</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.events.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.referrer}</TableCell>
              <TableCell>{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}